import { connect } from 'react-redux';

import McDimensioniPopupComponent from './McDimensioniPopup.component';

/** @namespace Pwa/Component/McDimensioniPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/McDimensioniPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(McDimensioniPopupComponent);
