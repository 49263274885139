import { connect } from 'react-redux';

import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';

import TogglePopupLink from './TogglePopupLink.component';

/** @namespace Pwa/Component/TogglePopupLink/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/TogglePopupLink/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch, props) => ({
    showPopup: (payload) => dispatch(showPopup(props.id, payload)),
    showOverlay: () => dispatch(toggleOverlayByKey(props.id)),
    hideOverlay: () => dispatch(hideActiveOverlay()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TogglePopupLink);
